<template>
	<div class="index-box">
		<vheader
        :navId=navId>
		</vheader>
		<div class="box01">
			<div class="mobile-box01">
				<el-carousel class="lunbo" height="540Px">
					<el-carousel-item v-for="item in listImg" :key="item.index">
						<img :src="item.imgUrl" alt="时尚服饰">
					</el-carousel-item>
				</el-carousel>
				<div class="box01-inside">
					<h1>恒安康泰，陪伴在您最需要的时刻 </h1>
					<p>恒安康泰提供医疗，开发，实业，教育，企项一体化的全面服务</p>
					<button @click="$router.push({name:'contactUs',query:{}})">联系我们</button>
				</div>
			</div>
		</div>
		<div class="box02">
			<div class="left02">
				<img src="../../assets/img/index/box02_01.png" alt="通知公告" title="通知公告">
				<span>通知公告</span>
			</div>
			<div class="right02">
				<p>
					<span class="roll">
						<span>恒安康泰获选北京海淀区中关村科技创业园入园企业</span>	&emsp;&emsp;&emsp;&emsp;
						<span>北京《中关村杂志》专访报道恒安康泰：《对话恒安康泰医疗，让赴京医疗更方便》</span>	&emsp;&emsp;&emsp;&emsp;
						<span>恒安康泰获中央电视台品牌支持： CCTV “蓝海工程”、“中国品牌故事”，CCTV“发现之旅”录制组邀请参与企业故事录制，节目在CCTV-1，CCTV经济，CCTV故事频道播出</span>	&emsp;&emsp;&emsp;&emsp;
						<span>恒安康泰获评入围“北京2021创客大赛”并进入复赛</span>	&emsp;&emsp;&emsp;&emsp;
						<span>恒安康泰获选北京海淀区中关村科技创业园入园企业</span>	&emsp;&emsp;&emsp;&emsp;
						<span>北京《中关村杂志》专访报道恒安康泰：《对话恒安康泰医疗，让赴京医疗更方便》</span>	&emsp;&emsp;&emsp;&emsp;
					</span>						
				</p>
			</div>
		</div>
		<div class="box03">
			<div class="box03-content">
				<div class="left03">
					<h1>ABOUT US</h1>
					<h2>关于我们</h2>
					<p>恒安康泰集团简介</p>
					<p>恒安康泰集团是北京市海淀区入园企业，是一所综合性科技服务公司，公司主要提供医疗健康服务，实业建设，系统开发等业务。旗下现有医疗，开发，实业，教育四大板块，恒安康泰立足北京，辐射全国。</p>
					<p>公司在北京海淀区政府和北京市政府的支持下，企业顺利入驻海淀区中关村科技创业园，先后获得中央电视台品牌支持， CCTV “蓝海工程”“中国品牌故事”，CCTV“发现之旅”录制组邀请参与企业故事录制。北京《中关村杂志》专访报道。获评入围“北京2021创客大赛”并进入复赛。</p>
				</div>
				<div class="right03">
					<el-carousel height="400Px" arrow="always">
						<el-carousel-item>
							<img src="../../assets/img/index/box03_01.jpg" alt="关于我们" title="关于我们">
						</el-carousel-item>
						<el-carousel-item>
							<img src="../../assets/img/index/box03_03.png" alt="关于我们" title="关于我们">
						</el-carousel-item>
					</el-carousel>
				</div>
				<ul class="bottom03">
					<li v-for="item in box03List" :key="item.id">
						<p>
							<span class="box03-p01">{{item.listNum}}</span>
							<span class="box03-p02">&nbsp;{{item.listSymbol}}</span>
						</p>
						<p class="box03-p03">{{item.listWord}}</p>
					</li>
				</ul>
			</div>
		</div>
		<div class="box04">
			<div class="box04-content">
				<div class="title04">
					<h1>BUSINESS</h1>
					<h2>业务板块</h2>
				</div>
				<div class="list04" v-for="item in box04List" :key="item.id" @click="$router.push({name:'businessSector',query:{}})" :title="item.ch">
					<img class="img04" :src="item.imgUrl" :alt="item.ch">
					<div>
						<img :src="item.icoUrl" :alt="item.ch">
						<p>
							{{item.ch}}							
						</p>
						<p>
							{{item.en}}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="box05">
			<div class="title05">
				<h1>ADVERTISING</h1>
				<h2>广告展示</h2>
			</div>
			<div class="list05" >
				<img v-for="item in box05List" :key="item.id" :src="item.imgUrl" @click="$router.push({name:'advertisement',query:{}})" alt="广告展示"  title="广告展示">
			</div>
		</div>
		<div class="box06">
			<div class="box06-content">
				<div class="title06">
					媒体报道
				</div>				
				<div class="img06">
					<img v-for="item in box06List" :key="item.index" :src="item.imgUrl" alt="媒体报道">
					<img v-for="item in box06List" :key="item.index" :src="item.imgUrl" alt="媒体报道">
					<img v-for="item in box06List" :key="item.index" :src="item.imgUrl" alt="媒体报道">
				</div>
			</div>			
		</div>
		<div class="box07">			
			<div class="title07">
				<h1>COMPANY HONOR</h1>
				<h2>公司荣誉</h2>
			</div>
			<el-carousel :interval="4000" type="card" height="350Px">
				<el-carousel-item v-for="item in box07List" :key="item.index">
					<img class="img07" :src="item.imgUrl" alt="公司荣誉">
				</el-carousel-item>
			</el-carousel>
		</div>
		<vfooter></vfooter>
		<GoTop/>
	</div>
</template>

<script>
import vheader from '../../components/vheader.vue'
import vfooter from '../../components/vfooter.vue'
import GoTop from '@/components/GoTop.vue'
export default { 
	name: '',
	data () {
		return{
			navId:0,
			listImg:[
				{imgUrl:require('../../assets/img/index/box01_01.jpg')},
				{imgUrl:require('../../assets/img/index/box01_02.jpg')},
			],
			box03List:[
				{listNum:'10',listSymbol:'余年',listWord:'行业经验'},
				{listNum:'100',listSymbol:'+',listWord:'合作企业'},
				{listNum:'20',listSymbol:'+',listWord:'服务领域'},
				{listNum:'5',listSymbol:'+',listWord:'分公司'},
				{listNum:'113.5',listSymbol:'万',listWord:'持证服务'},
			],
			box04List:[
				{
					imgUrl:require('../../assets/img/index/box04_01.jpg'),
					icoUrl:require('../../assets/img/index/box04_05.png'),
					ch:'医疗健康',
					en:'Medical health',
				},
				{
					imgUrl:require('../../assets/img/index/box04_02.jpg'),
					icoUrl:require('../../assets/img/index/box04_06.png'),
					ch:'系统开发',
					en:'system Development',
				},
				{
					imgUrl:require('../../assets/img/index/box04_03.jpg'),
					icoUrl:require('../../assets/img/index/box04_07.png'),
					ch:'实业建设',
					en:'Industry construction',
				},
				{
					imgUrl:require('../../assets/img/index/box04_04.jpg'),
					icoUrl:require('../../assets/img/index/box04_08.png'),
					ch:'教育咨询',
					en:'Education​ consultation',
				},
			],
			box05List:[
				{imgUrl:require('../../assets/img/index/box05_02.jpg')},
				// {imgUrl:require('../../assets/img/index/box05_03.jpg')},
				{imgUrl:require('../../assets/logo.png')},
				{imgUrl:require('../../assets/img/index/box05_04.jpg')},
				{imgUrl:require('../../assets/img/index/box05_05.jpg')},
				{imgUrl:require('../../assets/img/index/box05_06.jpg')},
				{imgUrl:require('../../assets/img/index/box05_08.jpg')},
			],
			box06List:[
				{imgUrl:require('../../assets/img/index/box06_02.jpg')},
				{imgUrl:require('../../assets/img/index/box06_03.jpg')},
				{imgUrl:require('../../assets/img/index/box06_04.jpg')},
				{imgUrl:require('../../assets/img/index/box06_05.jpg')},
				{imgUrl:require('../../assets/img/index/box06_06.jpg')},
			],
			box07List:[
				{imgUrl:require('../../assets/img/index/box07_01.png')},
				{imgUrl:require('../../assets/img/index/box07_01.png')},
				{imgUrl:require('../../assets/img/index/box07_01.png')},
				{imgUrl:require('../../assets/img/index/box07_01.png')},
				{imgUrl:require('../../assets/img/index/box07_01.png')},
			]
        }
	},
	props: {
	},
	methods:{	
		
	},
	components:{
		vheader,
		vfooter,
		GoTop
	},
}
</script>

<style scoped lang="less">
// pc端样式
@media screen and (min-width:750px){
	.index-box{
		.box01{	
			position: relative;
			color: #fff;
			img{
				width: 100%;
			}
			/deep/.el-carousel__button{
				width: 50Px;
				height: 5Px;
			}		
			/deep/.el-carousel__arrow{
				width: 80Px;
				height: 80Px;
				margin: 0 90Px;
				font-size: 45Px;
			}
			/deep/.el-carousel__indicator--horizontal{
				padding: 35Px 10PX;
			}
			.box01-inside{
				width: 700Px;
				left: 50%;
				margin-left: -600Px;
				position: absolute;
				top: 150Px;
				z-index: 10;
				h1{
					font-size: 43Px;
				}
				p{
					font-size: 15Px;
					margin-top: 25Px;
				}
				button{
					border: 2Px solid #fff;
					background-color: unset;
					color: #fff;
					width: 130Px;
					height: 43Px;
					margin-top: 35Px;
					cursor: pointer;
					transition: all 0.2s;
					&:hover{
						background: #fff;
						color: #093C6C;
					}
				}
			}
		}
		.box02{
			margin: 25Px auto 0;
			width: 1200Px;
			height: 90Px;
			background-color: #F5F5F5;
			display: flex;
			align-items: center;
			.left02{
				margin: 0 20Px 0 13Px;
				font-size: 16Px;
				display: flex;
				align-items: center;
				color: #5a5a5a;
				padding-right: 20Px;
				border-right: 1Px solid #ccc;
			}
			.right02{
				p{	
					width: 960Px;
					font-size: 14Px;
					word-break:keep-all;       /* 不换行 */
					white-space:nowrap;       /* 不换行 */
					overflow:hidden; 
					.roll{
						animation: roll 30s linear infinite;
					}
					@keyframes roll {
						100%{
							margin-left: -2510Px;
						}
					}
						
				}
			}
		}
		.box03{
			background: url(../../assets/img/index/box03_02.png) no-repeat center / 80%;
			.box03-content{
				width: 1200Px;
				padding:70Px 0;
				margin: auto;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.left03{
					width: 535Px;
					color: #5a5a5a;
					font-size: 14Px;
					line-height: 32Px;	
					:first-child{
						font-size: 42Px;
						color: #ddd;
					}
					:nth-child(2){
						width: 90Px;
						font-size: 22Px;
						color: #222;
						padding-top: 15Px;
						padding-bottom: 12Px;
						border-bottom: 1Px solid #093C6C;
					}
					:nth-child(3){
						font-size: 18Px;
						margin: 25Px 0 15Px;
					}
					:nth-child(4){
						margin-bottom: 20Px;
					}
				}
				.right03{
					width:600Px;					
					/deep/.el-carousel__button {
						display: none;
					}	
					/deep/.el-carousel__arrow{
						width: 55Px;
						height: 55Px;
						font-size: 35Px;
					}
					img{
						width: 600Px;
						
					}
				}
				.bottom03{
					width: 1200Px;
					display: flex;
					justify-content: space-between;
					margin-top: 55Px;
					
					li{
						border-right: 1Px solid #D7D7D7;
						color: #666;
						padding: 10Px 75Px 10Px 0;
						font-size: 18Px;	
						&:first-child{
							padding-left: 75Px ;
						}
						&:last-child{
							border: 0;
						}
						.box03-p01{
							font-size: 48Px;
							font-weight: 700;
							color: #093c6c;
						}
						.box03-p03{
							text-align: center;
						}
					}
				}
			}
		}
		.box04{
			padding: 60Px 0 30Px;
			background-color: #F6F8FB;
			.box04-content{
				width: 1200Px;
				margin: auto;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				.title04{
					width: 1200Px;
					:first-child{
						font-size: 42Px;
						color: #ddd;
						text-align: center;
					}
					:nth-child(2){
						width: 89Px;
						margin:0 auto 45Px;
						font-size: 22Px;
						padding-bottom: 13Px;
						color: #222;
						border-bottom: 1Px solid #093C6C;
					}
				}
				.list04{
					width: 580Px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					text-align: center;
					margin-bottom: 40Px;
					background: #fff;
					transition: all 0.2s;
					cursor: pointer;
						&:hover{
							// opacity: 0.8;
							transform: scale(1.04);
							box-shadow: 0 0 15Px #aaa;
						}
					.img04{
						width: 400Px;
						height: 280Px;
					}
					div{
						margin: auto;
						:nth-child(2){
							margin-top: 30Px;
							font-size: 18Px;
							color: #434343;
						}
						:last-child{
							font-size: 12Px;
							margin-top: 2Px;
							color: #999;
						}
					}
				}
			}
		}
		.box05{				
			width: 1200Px;
			margin: AUTO;
			padding: 60Px 0 55Px;	
			.title05{
				:first-child{
					font-size: 42Px;
					color: #ddd;
					text-align: center;
				}
				:nth-child(2){
					width: 89Px;
					margin:0 auto 45Px;
					font-size: 22Px;
					padding-bottom: 13Px;
					color: #222;
					border-bottom: 1Px solid #093C6C;
				}
			}
			.list05{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				img{
					width:390Px;
					height: 266Px;
					margin-bottom: 13Px;
					cursor: pointer;
					transition: all 0.2s;
					&:hover{
						opacity: 0.8;
					}
				}
			}
		}
		.box06{
			background: url(../../assets/img/index/box06_01.jpg) no-repeat center / 100%;
			.box06-content{
				width: 1200Px;
				overflow: hidden;
				margin: auto;
				padding: 60Px 0 65Px;
				.title06{
					width: 88Px;
					margin: auto;
					padding-bottom: 12Px;
					font-size: 22PX;
					color: #fff;
					font-weight: 700;
					border-bottom: 1Px solid #fff;
				}
				.img06{
					margin-top: 40Px;
					display: flex;
					animation: img06anim infinite linear 15s;
					justify-content: space-between;
					img{
						margin-right: 24Px;
					}
					@keyframes img06anim {
						100%{
							margin-left: -1050Px;
						}
					}
				}
			}				
		}
		.box07{
			width: 1000Px;
			margin:55Px auto 63Px;
			.title07{
				:first-child{
					font-size: 42Px;
					color: #ddd;
					text-align: center;
				}
				:nth-child(2){
					width: 89Px;
					margin:0 auto 45Px;
					font-size: 22Px;
					padding-bottom: 13Px;
					color: #222;
					border-bottom: 1Px solid #093C6C;
				}
			}
			.img07{
				width: 500Px;
				height: 380Px;
			}
			/deep/.el-carousel__button{
				width: 45Px;
				height: 5Px;
			}		
			/deep/.el-carousel__arrow{
				width: 70Px;
				height: 70Px;
				font-size: 40Px;
				margin: 0 80Px;
			}
			/deep/.el-carousel__indicator--horizontal{
				padding: 40Px 10PX 0;
			}
		}
	}
}

// 移动端样式
@media screen and (max-width:750px){
	.index-box{
		.box01{	
			position: relative;
			color: #fff;
			.mobile-box01{	
				height: 150px;
				overflow: hidden;			
				img{
					width: 130%;
				}
				/deep/.el-carousel__button{
					display: none;
				}		
				/deep/.el-carousel__arrow{
					display: none;
				}
				/deep/.el-carousel__indicator--horizontal{
					padding: 35px 10px;
				}
				.box01-inside{
					display: none;
				}
			}
		}
		.box02{
			height: 35px;
			background-color: #F5F5F5;
			display: flex;
			overflow: hidden;
			align-items: center;
			.left02{
				font-size: 12px;
				display: flex;
				align-items: center;
				color: #5a5a5a;
				border-right: 1px solid #ccc;
				span{
					width: 55px;
				}
				img{
					width: 30px;
				}
			}
			.right02{
				p{	
					color: #999;
					font-size: 14px;
					word-break:keep-all;       /* 不换行 */
					white-space:nowrap;       /* 不换行 */
					overflow:hidden; 
					.roll{
						animation: roll 35s linear infinite;
					}
					@keyframes roll {
						100%{
							margin-left: -2505px;
						}
					}
						
				}
			}
		}
		.box03{
			background: url(../../assets/img/index/box03_02.png) no-repeat center / 80%;
			.box03-content{
				width: 95%;
				padding:15px 0 30px;
				margin: auto;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.left03{
					width: 535px;
					color: #5a5a5a;
					font-size: 14px;
					:first-child{
						font-size: 25px;
						color: #ddd;
						text-align: center;
					}
					:nth-child(2){
						width: 65px;
						font-size: 16px;
						color: #222;
						margin: auto;
						padding-bottom: 5px;
						border-bottom: 1px solid #093C6C;
					}
					:nth-child(3){
						display: none;
					}
					:nth-child(4){
						margin: 15px 0 5px;
						line-height: 25px;	
					}
					:nth-child(5){
						line-height: 25px;	
					}
				}
				.right03{
					width:100%;		
					margin-top: 15px;		
					height: 260px;
					border-radius: 10px;
					overflow: hidden;
					/deep/.el-carousel__button {
						display: none;
					}	
					/deep/.el-carousel__arrow{
						display: none;
					}
					img{
						width: 100%;
						height: 310Px;
					}
				}
				.bottom03{
					display: none;
				}
			}
		}
		.box04{
			padding: 20px 0 30px;
			background-color: #F6F8FB;
			.box04-content{
				width: 95%;
				margin: auto ;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				.title04{
					width: 95%;
					:first-child{
						font-size: 25px;
						color: #ddd;
						text-align: center;
					}
					:nth-child(2){
						width: 65px;
						font-size: 16px;
						color: #222;
						margin:0 auto 5px;
						padding-bottom: 5px;
						border-bottom: 1px solid #093C6C;
					}
				}
				.list04{
					width: 100%;
					display: flex;
					overflow: hidden;
					justify-content: space-between;
					align-items: center;
					text-align: center;
					margin-top: 15px;
					background: #fff;
					border-radius: 7px;
					.img04{
						width: 210px;
					}
					div{
						margin: auto;
						img{
							width: 60px;
						}
						:nth-child(2){
							font-size: 15px;
							color: #434343;
						}
						:last-child{
							font-size: 10px;
							margin-top: 3px;
							color: #999;
						}
					}
				}
			}
		}
		.box05{				
			width: 95%;
			margin: AUTO;
			padding: 20px 0 30px;	
			.title05{
				width: 95%;
				:first-child{
					font-size: 25px;
					color: #ddd;
					text-align: center;
				}
				:nth-child(2){
					width: 65px;
					font-size: 16px;
					color: #222;
					margin:0 auto 12px;
					padding-bottom: 5px;
					border-bottom: 1px solid #093C6C;
				}
			}
			.list05{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				img{
					width: 49%;
					height: 123px;
					margin-top: 7px;
					cursor: pointer;
					transition: all 0.2s;
					&:hover{
						opacity: 0.8;
					}
					&:last-child{ 
					}
				}
			}
		}
		.box06{
			background: #ccc url(../../assets/img/index/box06_01.jpg) no-repeat center / 170%;
			.box06-content{
				overflow: hidden;
				margin: auto;
				padding: 30px  0;
				.title06{
					width: 72px;
					margin: auto;
					padding-bottom: 10px;
					font-size: 18px;
					color: #fff;
					font-weight: 700;
					border-bottom: 1px solid #fff;
				}
				.img06{
					overflow: hidden;
					margin-top: 20px;
					display: flex;
					animation: img06anim infinite linear 10s;
					justify-content: space-between;
					img{
						width: 150px;
						margin-right: 24px;
					}
					@keyframes img06anim {
						100%{
							margin-left: -870px;
						}
					}
				}
			}
				
		}
		.box07{
			width: 95%;
			height: 200px;
			overflow: hidden;
			margin:30px auto;
			.title07{
				:first-child{
					font-size: 22px;
					color: #ddd;
					text-align: center;
				}
				:nth-child(2){
					width: 65px;
					font-size: 16px;
					color: #222;
					margin:0 auto 20px;
					padding-bottom: 8px;
					border-bottom: 1px solid #093C6C;
				}
			}
			.img07{
				width: 180px;
			}
			/deep/.el-carousel__button{
				width: 45px;
				display: none;
				height: 5px;
			}		
			/deep/.el-carousel__arrow{
				display: none;
			}
			/deep/.el-carousel__indicator--horizontal{
				padding: 40px 10px 0;
			}
		}
	}

}
</style>
